import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const Projects = lazy(() => import('../../container/project'));
const Project = lazy(() => import('../../container/project/Show'));

function ProjectRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/view`} component={Projects} />
      <Route path={`${path}/:projectId`} component={Project} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default ProjectRoutes;
