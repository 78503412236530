import actions from './actions';

const {
  PROJECTS_BEGIN,
  PROJECTS_SUCCESS,
  PROJECTS_ERR,

  PROJECT_REQUEST,
  PROJECT_REQUEST_ERR,

  SINGLE_PROJECT_BEGIN,
  SINGLE_PROJECT_SUCCESS,
  SINGLE_PROJECT_ERR,

  SINGLE_PROJECT_UPDATE,
  PROJECT_MEMBER_UPDATE,
  COMPLETE_PROJECT_SUCCESS,
} = actions;

const projectsInitialState = {
  data: {},
  loading: false,
  error: null,
};

const projectsReducer = (state = projectsInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case PROJECTS_BEGIN:
      return {
        ...projectsInitialState,
        loading: true,
      };
    case PROJECTS_SUCCESS:
      return {
        ...projectsInitialState,
        data,
        loading: false,
      };
    case PROJECTS_ERR:
      return {
        ...projectsInitialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const projectInitialState = {
  data: {},
  loading: true,
  error: null,
};

const projectReducer = (state = projectInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_REQUEST_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };

    case SINGLE_PROJECT_BEGIN:
      return {
        ...projectInitialState,
        loading: true,
      };

    case SINGLE_PROJECT_SUCCESS:
      return {
        ...projectInitialState,
        data,
        loading: false,
      };
    case SINGLE_PROJECT_ERR:
      return {
        ...projectInitialState,
        error: err,
        loading: false,
      };
    case SINGLE_PROJECT_UPDATE:
      return {
        ...state,
        data: { ...state.data, ...data },
        loading: false,
      };
    case PROJECT_MEMBER_UPDATE:
      return {
        ...state,
        data: { ...state.data, members: data },
      };
    case COMPLETE_PROJECT_SUCCESS:
      return {
        ...state,
        data: { ...state.data, status: 'selesai' },
      };
    default:
      return state;
  }
};

export { projectReducer, projectsReducer };
