const actions = {
  PROJECTS_BEGIN: 'PROJECTS_BEGIN',
  PROJECTS_SUCCESS: 'PROJECTS_SUCCESS',
  PROJECTS_ERR: 'PROJECTS_ERR',

  PROJECT_REQUEST: 'PROJECT_REQUEST',
  PROJECT_REQUEST_ERR: 'PROJECT_REQUEST_ERR',

  SINGLE_PROJECT_BEGIN: 'SINGLE_PROJECT_BEGIN',
  SINGLE_PROJECT_SUCCESS: 'SINGLE_PROJECT_SUCCESS',
  SINGLE_PROJECT_ERR: 'SINGLE_PROJECT_ERR',

  SINGLE_PROJECT_UPDATE: 'SINGLE_PROJECT_UPDATE',
  PROJECT_MEMBER_UPDATE: 'PROJECT_MEMBER_UPDATE',
  COMPLETE_PROJECT_SUCCESS: 'COMPLETE_PROJECT_SUCCESS',

  projectsBegin: () => {
    return {
      type: actions.PROJECTS_BEGIN,
    };
  },

  projectsSuccess: (data) => {
    return {
      type: actions.PROJECTS_SUCCESS,
      data,
    };
  },

  projectsErr: (err) => {
    return {
      type: actions.PROJECTS_ERR,
      err,
    };
  },

  projectRequest: () => {
    return {
      type: actions.PROJECT_REQUEST,
    };
  },

  projectRequestErr: () => {
    return {
      type: actions.PROJECT_REQUEST_ERR,
    };
  },

  singleProjectBegin: () => {
    return {
      type: actions.SINGLE_PROJECT_BEGIN,
    };
  },

  singleProjectSuccess: (data) => {
    return {
      type: actions.SINGLE_PROJECT_SUCCESS,
      data,
    };
  },

  singleProjectErr: (err) => {
    return {
      type: actions.SINGLE_PROJECT_ERR,
      err,
    };
  },

  singleProjectUpdate: (data) => {
    return {
      type: actions.SINGLE_PROJECT_UPDATE,
      data,
    };
  },

  projectMemberUpdate: (data) => {
    return {
      type: actions.PROJECT_MEMBER_UPDATE,
      data,
    };
  },

  completeProjectSuccess: () => {
    return {
      type: actions.COMPLETE_PROJECT_SUCCESS,
    };
  },
};

export default actions;
