const actions = {
  GET_SETTING_BEGIN: 'GET_SETTING_BEGIN',
  GET_SETTING_SUCCESS: 'GET_SETTING_SUCCESS',
  GET_SETTING_ERR: 'GET_SETTING_ERR',
  UPDATE_SETTING_BEGIN: 'UPDATE_SETTING_BEGIN',
  UPDATE_SETTING_SUCCESS: 'UPDATE_SETTING_SUCCESS',
  UPDATE_SETTING_ERR: 'UPDATE_SETTING_ERR',

  getSettingBegin: () => {
    return {
      type: actions.GET_SETTING_BEGIN,
    };
  },

  getSettingSuccess: (data) => {
    return {
      type: actions.GET_SETTING_SUCCESS,
      data,
    };
  },

  getSettingErr: (err) => {
    return {
      type: actions.GET_SETTING_ERR,
      err,
    };
  },

  updateSettingBegin: () => {
    return {
      type: actions.UPDATE_SETTING_BEGIN,
    };
  },

  updateSettingSuccess: (data) => {
    return {
      type: actions.UPDATE_SETTING_SUCCESS,
      data,
    };
  },

  updateSettingErr: (err) => {
    return {
      type: actions.UPDATE_SETTING_ERR,
      err,
    };
  },
};

export default actions;
