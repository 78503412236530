import actions from './actions';

const {
  GET_SETTING_BEGIN,
  GET_SETTING_SUCCESS,
  GET_SETTING_ERR,
  UPDATE_SETTING_BEGIN,
  UPDATE_SETTING_SUCCESS,
  UPDATE_SETTING_ERR,
} = actions;

const initState = {
  appName: null,
  logo: null,
  smallLogo: null,
  authImage: null,
  companyName: null,
  street: null,
  zipCode: null,
  city: null,
  province: null,
  country: null,
  email: null,
  phone: null,
  loading: false,
  error: null,
};

const settingReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SETTING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SETTING_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case GET_SETTING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_SETTING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SETTING_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case UPDATE_SETTING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { settingReducer };
