import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const Client = lazy(() => import('../../container/client'));
const Create = lazy(() => import('../../container/client/Create'));
const Edit = lazy(() => import('../../container/client/Edit'));

function ClientRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={Client} />
      <Route exact path={`${path}/create`} component={Create} />
      <Route exact path={`${path}/:clientId`} component={Client} />
      <Route exact path={`${path}/:clientId/edit`} component={Edit} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default ClientRoutes;
