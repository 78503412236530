import React from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const role = useSelector((state) => state.auth.user.role);
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const mainPathSplit = pathName.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit[1] !== '' ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 0) setOpenKeys([]);
  };

  const selectedKeys = () => {
    const keys = [];
    switch (mainPathSplit.length) {
      case 1:
        if (mainPathSplit[0] === '') {
          keys[0] = 'dashboard';
        } else {
          [keys[0]] = mainPathSplit;
        }
        break;

      case 2:
        [, keys[0]] = mainPathSplit;
        break;

      case 3:
        if (mainPathSplit[1] === 'project') {
          keys[0] = 'project';
        } else if (mainPathSplit[1] === 'setting') {
          keys[0] = 'setting';
        } else {
          [, , keys[0]] = mainPathSplit;
        }
        break;

      default:
        if (mainPathSplit[1] === 'project') {
          keys[0] = 'project';
        } else if (mainPathSplit[1] === 'sales') {
          [, , keys[0]] = mainPathSplit;
        } else {
          [, , , keys[0]] = mainPathSplit;
        }
        break;
    }
    return keys;
  };

  const items = [
    {
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}dashboard`}>
          Dashboard
        </NavLink>
      ),
      key: 'dashboard',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}dashboard`}>
          <FeatherIcon icon="grid" />
        </NavLink>
      ),
    },
    role === 'admin' && {
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}client`}>
          Client
        </NavLink>
      ),
      key: 'client',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}client`}>
          <FeatherIcon icon="users" />
        </NavLink>
      ),
    },
    {
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}project/view`}>
          Project
        </NavLink>
      ),
      key: 'project',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}project/view`}>
          <FeatherIcon icon="folder" />
        </NavLink>
      ),
    },
    role === 'admin' && {
      label: <NavLink to="#">Sales</NavLink>,
      key: 'sales',
      children: [
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}sales/purchase-order`}>
              Purchase Order
            </NavLink>
          ),
          key: 'purchase-order',
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}sales/invoice`}>
              Invoice
            </NavLink>
          ),
          key: 'invoice',
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}sales/payment`}>
              Payment
            </NavLink>
          ),
          key: 'payment',
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}sales/delivery-note`}>
              Surat Jalan
            </NavLink>
          ),
          key: 'delivery-note',
        },
        {
          label: (
            <NavLink onClick={toggleCollapsed} to={`${path}sales/product`}>
              Produk
            </NavLink>
          ),
          key: 'product',
        },
      ],
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to="#">
          <FeatherIcon icon="grid" />
        </NavLink>
      ),
    },
    role === 'admin' && {
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}staff`}>
          Staff
        </NavLink>
      ),
      key: 'staff',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}staff`}>
          <FeatherIcon icon="users" />
        </NavLink>
      ),
    },
    role === 'admin' && {
      label: (
        <NavLink onClick={toggleCollapsed} to={`${path}setting`}>
          Setting
        </NavLink>
      ),
      key: 'setting',
      icon: !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}setting`}>
          <FeatherIcon icon="settings" />
        </NavLink>
      ),
    },
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={!topMenu ? selectedKeys() : []}
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 1 ? mainPathSplit[0] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      selectedKeys={selectedKeys()}
      items={items}
    />
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
