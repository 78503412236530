import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  getSettingBegin,
  getSettingSuccess,
  getSettingErr,
  updateSettingBegin,
  updateSettingSuccess,
  updateSettingErr,
} = actions;

const getSetting = () => {
  return async (dispatch) => {
    try {
      dispatch(getSettingBegin());

      const response = await DataService.get('/setting');
      dispatch(
        getSettingSuccess({
          appName: response.data.setting.app_name,
          logo: response.data.setting.logo_url,
          smallLogo: response.data.setting.small_logo_url,
          authImage: response.data.setting.auth_image_url,
          companyName: response.data.setting.company_name,
          street: response.data.setting.street,
          zipCode: response.data.setting.zip_code,
          city: response.data.setting.city,
          province: response.data.setting.province,
          country: response.data.setting.country,
          email: response.data.setting.email,
          phone: response.data.setting.phone,
        }),
      );

      let link = document.querySelector("link[rel~='icon']");
      if (response.data.setting.small_logo_url) {
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = response.data.setting.small_logo_url;
      }
    } catch (err) {
      dispatch(getSettingErr(err));
    }
  };
};

const updateAppSetting = (data) => {
  return async (dispatch) => {
    try {
      dispatch(updateSettingBegin());

      const response = await DataService.post('/setting/app', data);

      dispatch(
        updateSettingSuccess({
          appName: response.data.setting.app_name,
          logo: response.data.setting.logo_url,
          smallLogo: response.data.setting.small_logo_url,
          authImage: response.data.setting.auth_image_url,
          companyName: response.data.setting.company_name,
          street: response.data.setting.street,
          zipCode: response.data.setting.zip_code,
          city: response.data.setting.city,
          province: response.data.setting.province,
          country: response.data.setting.country,
          email: response.data.setting.email,
          phone: response.data.setting.phone,
        }),
      );

      notification.success({
        message: 'Pengaturan berhasil diperbaharui !',
      });
    } catch (err) {
      dispatch(updateSettingErr(err));
      return Promise.reject(err);
    }
  };
};

const updateContactSetting = (data) => {
  return async (dispatch) => {
    try {
      dispatch(updateSettingBegin());

      const response = await DataService.post('/setting/contact', data);

      dispatch(
        updateSettingSuccess({
          appName: response.data.setting.app_name,
          logo: response.data.setting.logo_url,
          smallLogo: response.data.setting.small_logo_url,
          authImage: response.data.setting.auth_image_url,
          companyName: response.data.setting.company_name,
          street: response.data.setting.street,
          zipCode: response.data.setting.zip_code,
          city: response.data.setting.city,
          province: response.data.setting.province,
          country: response.data.setting.country,
          email: response.data.setting.email,
          phone: response.data.setting.phone,
        }),
      );

      notification.success({
        message: 'Pengaturan berhasil diperbaharui !',
      });
    } catch (err) {
      dispatch(updateSettingErr(err));
      return Promise.reject(err);
    }
  };
};

export { getSetting, updateAppSetting, updateContactSetting };
