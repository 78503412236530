import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Client from './client';
import Dashboard from './dashboard';
import Project from './project';
import Sales from './sales';
import Staff from './staff';
import withAdminLayout from '../../layout/withAdminLayout';

const NotFound = lazy(() => import('../../container/pages/404'));
const Setting = lazy(() => import('../../container/setting'));
const Profile = lazy(() => import('../../container/profile/user'));

const Home = () => {
  const { path } = useRouteMatch();
  const user = useSelector((state) => state.auth.user);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route path={`${path}dashboard`} component={Dashboard} />
        {user.role === 'admin' && <Route path={`${path}staff`} component={Staff} />}
        {user.role === 'admin' && <Route path={`${path}client`} component={Client} />}
        <Route path={`${path}project`} component={Project} />
        {user.role === 'admin' && <Route path={`${path}sales`} component={Sales} />}
        {user.role === 'admin' && <Route path={`${path}setting`} component={Setting} />}
        <Route path={`${path}profile`} component={Profile} />
        <Route exact path={['/sign-in', '/']}>
          <Redirect to={`${path}dashboard`} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default withAdminLayout(Home);
