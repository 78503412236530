import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../../container/pages/404'));
const DeliveryNote = lazy(() => import('./delivery-note'));
const Invoice = lazy(() => import('./invoice'));
const Payment = lazy(() => import('./payment'));
const Product = lazy(() => import('../../../container/product'));
const PurchaseOrder = lazy(() => import('./purchase-order'));

function SalesRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/delivery-note`} component={DeliveryNote} />
      <Route path={`${path}/purchase-order`} component={PurchaseOrder} />
      <Route path={`${path}/invoice`} component={Invoice} />
      <Route path={`${path}/payment`} component={Payment} />
      <Route path={`${path}/product`} component={Product} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default SalesRoutes;
