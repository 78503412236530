import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotFound = lazy(() => import('../../container/pages/404'));
const Staff = lazy(() => import('../../container/staff'));
const Create = lazy(() => import('../../container/staff/Create'));
const Edit = lazy(() => import('../../container/staff/Edit'));

function StaffRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={Staff} />
      <Route exact path={`${path}/create`} component={Create} />
      <Route exact path={`${path}/:staffId`} component={Staff} />
      <Route exact path={`${path}/:staffId/edit`} component={Edit} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default StaffRoutes;
